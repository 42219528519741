.progress-wrapper {
  width: 100%;
  overflow: hidden;
}
.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  padding: 3px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
}

.progress-bar-fill {
  position: relative;
  display: block;
  height: 8px;
  background-color: var(--pethyGreen);
  border-radius: 3px;
  width: 100%;

  /* transition: width 500ms linear; */
  animation: infinity-load 1s ease-in-out infinite;
}

@keyframes infinity-load {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}