:root {
  /* --green: #59b559; */
  --pethyGreen-rgb: 89, 181, 89;
  --danger-rgb: 200, 0, 0;
  --pethyGreen: rgb(var(--pethyGreen-rgb));
  --danger: rgb(var(--danger-rgb));
  --notification-rgb: 0, 0, 255;
  --notification: rgb(var(--notification-rgb));
  --primary: var(--pethyGreen);
  --primary-lighter: rgba(var(--pethyGreen-rgb), 0.6);
  --secondary: var(--pethyGreen);
  --vintageYellow: #dad355;
  --lightGray: #EEE;
  --shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.25);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* .wcw button {
  border: 0;
  background: none;
  padding: 0;
} */

@layer components {
  .wcw .btn,
  .wcw .badge,
  .wcw .btn-primary,
  .wcw .btn-danger,
  .wcw .btn-gray,
  .wcw .btn-secondary {
    background-color: var(--primary);
    @apply border-0 text-white py-1 px-3 rounded m-1 relative cursor-pointer inline-block;
  }
  .wcw .btn:disabled {
    @apply opacity-40
  }
  .wcw .btn-danger {
    @apply bg-red-500 
  }
  .wcw .btn-gray {
    @apply bg-gray-500
  }
  .wcw .lg-btn {
    color: var(--primary);
    @apply bg-gray-100 uppercase m-1 relative inline-block py-2 px-4
  }
  .wcw .lg-btn:hover {
    @apply bg-gray-200  
  }
}
.wcw input.input, .wcw textarea.input, .wcw select.select, .wcw .textarea {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0.5rem 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: var(--lightGray);
  background-clip: padding-box;
  border: 0px solid #ced4da;
  /* border-radius: .25rem; */
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
textarea, .textarea {
  min-height: 6rem;
}
.wcw .page-details {
  transition: all 0.3s linear;
}
/* Second destroy to maintain on outfocus */
.wcw .highlight-destroy, .wcw .highlight-destroy-2 {
  background-color: rgba(var(--danger-rgb), 0.2);
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ff0000' fill-opacity='0.1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
.wcw .highlight-destroy img {
  opacity: 0.5;
}
.wcw .highlight-update {
  background-color: rgba(var(--pethyGreen-rgb), 0.5);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.wcw .highlight-update .btn-primary {
  color: black;
  background-color: var(--lightGray);
}
.wcw .animate-delete-wrapper .animate-delete {
  /* display: none; */
  opacity: 0;
  transition: all 0.3s linear;
}
.wcw .animate-delete-wrapper:hover .animate-delete {
  /* display: block; */
  opacity: 1;
}
/* .wcw .animated-sidebar {
} */
.animated-hover-hide {
  opacity: 0;
  transition: opacity 0.2s linear;
}
.wcw .animated-show-hidden:hover .animated-hover-hide {
  opacity: 1;
}
.wcw .DraftEditor-root {
  display: inline-block;
}
.wcw .DraftEditor-root div {
  display: inline-block;
}

/* 
.wcw .pethy-admin.hidden {
  border: 1px dotted rgba(0, 0, 0, 0.25);
  display: inherit;
}
.wcw .pethy-admin.hidden.border-dashed {
  border: 1px dashed rgba(0, 0, 0, 0.25);
} 
*/